<template>
    <section>
        <div class="row mx-0 pl-4 align-items-center mt-4 mb-3">
            <div class="col-auto px-0 f-17 f-500 text-general">
                Rango de análisis
            </div>
            <div class="col-3">
                <el-select v-model="rangoSelected" size="small" class="w-100">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-3">
                <el-date-picker
                v-model="fecha"
                class="w-100"
                size="medium"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                :disabled="disable_fecha"
                popper-class="date-picker-edit-popper"
                type="daterange"
                unlink-panels
                start-placeholder="Fecha inicio"
                end-placeholder="Fecha fin"
                :clearable="false"
                />
            </div>
            <button class="btn btn-general px-3 f-14" @click="test()">
                Mostrar Informe 
            </button>
        </div>
        <section class="position-relative">
            <cargando v-if="cargandoSegunda" /> 
            <div class="bg-white br-12 py-3 px-3">
                <p class="text-general f-500 f-18 mb-3">
                    {{ $config.vendedor }} con mayor cantidad de ventas
                </p>
                <div class="row mx-0">
                    <div class="col pl-0">
                        <div class="row mx-0">
                            <div v-for="leechero in leecherosMasVentas" :key="leechero.id_tienda" class="mr-5 mb-3" style="width:100px;">
                                <div class="row mx-0 justify-center">
                                    <img :src="leechero.logo_mini || '/img/no-imagen/sin_cliente.svg'" width="72px" height="72px" class="obj-cover br-4 border" />
                                </div>
                                <p class="text-general f-14 text-center mt-2">
                                    {{ leechero.nombre }}
                                </p>
                                <p class="text-general f-14 text-center f-500 my-1">
                                    {{ separadorNumero(leechero.valor) }}
                                </p>
                                <p class="text-general f-14 text-center tres-puntos">
                                    {{ leechero.team }}
                                </p>
                                <p class="text-general f-14 text-center tres-puntos mt-1">
                                    {{ leechero.cedis }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="teams.length > 5" class="bg-general3 br-4 cr-pointer text-white d-flex flex-column align-items-center justify-content-center mr-4" style="width:72px;height:72px;" @click="modalGris(4)">
                        <p class="f-15 text-center">
                            Ver más
                            <br />
                            <i class="icon-angle-right f-17" />
                        </p>
                    </div>
                </div>
            </div>
            <div class="bg-white br-12 py-3 px-3 mt-3">
                <p class="text-general f-500 f-18 mb-3">
                    {{ $config.cliente }} con mayor cantidad de compras
                </p>
                <div class="row mx-0">
                    <div v-for="cliente in clientesMasCompras" :key="cliente.created_by" class="mr-5 mb-4" style="width:100px;">
                        <div class="row mx-0 justify-center">
                            <img :src="cliente.imagen || '/img/no-imagen/sin_cliente.svg'" class="obj-cover rounded-circle" width="72px" height="72px" />
                        </div>
                        <p class="text-general text-center f-14 mt-2 tres-puntos">
                            {{ cliente.nombre }}
                        </p>
                        <p class="text-general text-center f-14 f-500 mt-2 tres-puntos">
                            {{ separadorNumero(cliente.valor) }}
                        </p>
                        <p class="text-general text-center f-14 mt-2 tres-puntos">
                            {{ cliente.team }}
                        </p>
                        <p class="text-general text-center f-14 f-500 mt-3 tres-puntos">
                            {{ cliente.tienda }}
                        </p>
                        <p class="text-general text-center f-14 mt-2 tres-puntos">
                            {{ cliente.cedis }}
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="position-relative">
            <cargando v-if="cargandoTercera" />
            <div class="bg-white br-12 py-3 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto px-0 f-18 f-500 text-general">
                        Ventas vs Cantidad de pedidos
                    </div>
                    <div v-if="teams.length > 5" class="cr-pointer bg-general3 text-white br-20 f-14 px-3 d-middle-center ml-auto" @click="modalGris(5)">
                        Ver más
                    </div>
                </div>
                <echart :options="ventas_cantidad" />
            </div>
            <div class="bg-white br-12 py-3 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto px-0 f-18 f-500 text-general">
                        Ticket promedio vs Clientes compradores
                    </div>
                    <div v-if="teams.length > 5" class="cr-pointer bg-general3 text-white br-20 f-14 px-3 d-middle-center ml-auto" @click="modalGris(6)">
                        Ver más
                    </div>
                </div>
                <echart :options="ticket_clientes" />
            </div>
        </section>
        <section-products :teams="teams" :changed="changed" :changed-fecha="changedFecha" :fecha="fecha" />
        <modal-progress-gris ref="progressGris" :teams="teams" show-fecha :fecha="fecha" />
    </section>
</template>

<script>
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')

import comparativa from "~/services/teams/comparativa"

import moment from 'moment'

const simpleBar = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    xAxis: {
        type: 'category',
        show: false
    },
    dataset:{
        dimensions:[],
        source: []
    },
    yAxis: {
        type: 'value',
        axisLine: {
            lineStyle: {
                color: '#F4F4F4'
            }
        },
        axisLabel: {
            color: '#707070'
        },
        splitLine: {
            lineStyle: {
                color: '#F4F4F4'
            }
        }
    },
    series: [
        {
            type: 'bar',
            barWidth: 50,
            itemStyle: {
                color: '#DBDBDB'
            }
        },
    ]
}
export default {
    components: {
        sectionProducts: () => import('./products.vue'),
        modalProgressGris: () => import('../partials/modalProgressGris.vue'),

    },
    props: {
        teams: {
            type: Array,
            default: () => []
        },
        changed: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            ventas_cantidad:_.cloneDeep(simpleBar),
            ticket_clientes: _.cloneDeep(simpleBar),
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rangoSelected: 5,
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            disable_fecha: true,
            leecherosMasVentas: [],
            clientesMasCompras: [],
            changedFecha: true,
            cargandoSegunda: true,
            cargandoTercera: true,
        }
    },
    watch: {
        
        rangoSelected(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        changed(){
            this.onInit()
        }
    },
    methods: {
        modalGris(tipo){
            this.$refs.progressGris.toggle(tipo);
        },
        async onInit(){
            await this.segundasGraficas()
            await this.tercerasGraficas()
        },
        async test(){
            this.onChangeFecha()
            await this.segundasGraficas()
            await this.tercerasGraficas()

        },
        async onChangeFecha(){
            this.cargandoSegunda = true
            this.cargandoTercera = true

            this.changedFecha = !this.changedFecha
            /* await this.segundasGraficas()
            await this.tercerasGraficas() */
        },
        async segundasGraficas(){
            try {
                this.cargandoSegunda = true
                const teams = this.teams.map(el => el.id).toString()

                const params = {
                    teams,
                    fecha: this.fecha
                }
                const { data } = await comparativa.segundasGraficas(params)
                this.leecherosMasVentas = data.leecherosMasVentas
                this.clientesMasCompras = data.clientesMasCompras
            } catch (error){
                this.error_catch(error)
            } finally{
                this.cargandoSegunda = false

            }
        },
        async tercerasGraficas(){
            try {
                this.cargandoTercera = true
                const teams = this.teams.map(el => el.id).toString()

                const params = {
                    teams,
                    fecha: this.fecha
                }
                const { data } = await comparativa.tercerasGraficas(params)
                
                this.ventas_cantidad.dataset = {
                    dimensions: ['nombre', 'suma', 'count_pedidos'],
                    source: data.ventasCantidadPedidos
                }


                this.ticket_clientes.dataset = {
                    dimensions: ['nombre', 'suma', 'count_created'],
                    source: data.ticketPromedio
                }

                const yAxis = [
                    {
                        type: 'value',
                        scale: true,
                    // name: 'Valor',
                    },
                    {
                        type: 'value',
                        name: 'Pedidos',
                        position: 'right',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colorGeneral,
                            }
                        },
                    }
                ]

                const series = [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Pedidos',
                        symbol: 'circle',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]

                const yAxis1 = [
                    {
                        type: 'value',
                        scale: true,
                    // name: 'Valor',
                    },
                    {
                        type: 'value',
                        name: 'Clientes',
                        position: 'right',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colorGeneral,
                            }
                        },
                    }
                ]

                const series1 = [
                    {
                        name: 'Ticket promedio',
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Clientes',
                        symbol: 'circle',
                        type: 'line',
                        yAxisIndex: 1,
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]

                this.ventas_cantidad.yAxis = yAxis
                this.ventas_cantidad.series = series
                this.ticket_clientes.yAxis = yAxis1
                this.ticket_clientes.series = series1
            } catch (error){
                this.error_catch(error)
            } finally{
                this.cargandoTercera = false
            }
        }
    }

}
</script>

<style>

</style>